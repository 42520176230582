<template>
  <vs-row
    vs-justify="center"
    vs-align="center"
    class="full-height login-register-bg"
  >
    <vs-col
      type="flex"
      vs-justify="center"
      vs-align="center"
      class="login-register-box"
      vs-lg="12"
      vs-xs="12"
    >
      <vs-card class=" mb-0">
        <div slot="header">
          <div slot="media">
            <img src="@/assets/images/logo/logo-light-icon.png">
          </div>
          <h3 class="mb-1">
            {{ msg }}
          </h3>
          <p class="mb-0">
            Bem vindo, por favor entre com sua conta.
          </p>
        </div>
        <vs-alert
          v-if="errors.length"
          class="mb-3 mt-2"
          color="danger"
          :active="inputValid"
        >
          <b>por favor verifique:</b>
          <ul class="common-list">
            <li
              v-for="error in errors"
              :key="error"
            >
              {{ error }}
            </li>
          </ul>
        </vs-alert>
        <form
          novalidate="true"
          @submit="checkForm"
        >
          <vs-input
            v-model="email"
            icon-after="true"
            size="large"
            class="w-100 mb-4 mt-2 vs-input-large"
            icon="person"
            placeholder="Email ID"
            type="email"
          />
          <vs-input
            v-model="pwd"
            icon-after="true"
            size="large"
            class="w-100 vs-input-large"
            type="password"
            icon="lock"
            placeholder="Password"
          />
          <div class="d-flex pt-3 pb-3">
            <vs-checkbox v-model="rememberme">
              Lembrar-me?
            </vs-checkbox>
            <router-link
              to="forgotpassword"
              class="ml-auto"
            >
              Esqueceu a senha?
            </router-link>
          </div>
          <input
            class="btn-block btn btn-primary submit-button"
            type="submit"
            value="Login"
            @click.stop.prevent="checkForm"
          >
        </form>
        <!--
     <div class="d-flex justify-content-center mt-3">
        Don't have an account?  &nbsp; <router-link to="/register"> Create an Account</router-link>
     </div>
     <hr class="custom-hr"/>
     <div class="mt-2 text-center"><b>Email ID:</b> admins@admin.com, <b>pwd:</b> admin</div>
    -->
      </vs-card>
    </vs-col>
  </vs-row>
</template>

<script>
export default {
  name: 'Login',
  data: ()=>({
    msg: 'Dashboard Login',
    rememberme: false,
    email: '',
    pwd: '',
    errors: []
  }),
  computed: {
    inputValid(){
      if(this.validEmail(this.email) && this.pwd){
        return false
      } else {
        return true
      }
    }
  },
  methods: {
    checkForm: function(e) {
      this.errors = [];
      if(!this.email) {
        this.errors.push('Entrar com usuário.');
      } else if(!this.validEmail(this.email)) {
        this.errors.push('Usuário ínvalido.');
      } else {
        //this.processingApi = true;
        this.$store.dispatch('set_isLoadingActive', true)
        setTimeout(() => {
          this.$store.dispatch('set_isLoadingActive', false)
        }, 15000);
        this.$router.push('/dashboards/general-surveys');
      }
      if (!this.pwd) this.errors.push('Entrar com usuário.');
      if(!this.errors.length) return true;
      e.preventDefault();

    },
    /* eslint-disable */
    validEmail:function(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
    return re.test(email);
    },
    /* eslint-enable */
    submit(){
      if (this.validEmail(this.email))
      {
        if (this.email == 'admin@fanbase.com.br' && this.pwd == '@dm!n')
          this.$router.push('/search')
        else
          this.errors.push('Incorrect Data.')
      }
    }
  }
}
</script>
<style>
    .login-register-bg {
        /*margin: 0 auto;
        background: url(../../assets/images/background/auth-bg.p) ;
        background-repeat: no-repeat;
        background-size: 666px 300px;*/
    }
.login-register-box{
  max-width:400px;
}

</style>

